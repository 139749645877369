"use client";

import type { ReactNode } from "react";
import {
  AssistantRuntimeProvider,
  TextContentPart,
  useLocalRuntime,
  type ChatModelAdapter,
} from "@assistant-ui/react";

const MyModelAdapter: ChatModelAdapter = {
  async run({ messages, abortSignal }) {
    // Extract all messages in the chat.
    const apiBaseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;

    // Format the messages array to include only the relevant parts for the backend.
    const formattedMessages = messages.map((message) => ({
      role: message.role, // 'user' or 'assistant'
      content: (message.content[0] as TextContentPart).text,
    }));

    // Get the most recent user message
    const mostRecentMsg = formattedMessages[formattedMessages.length - 1].content;

    const result = await fetch(`${apiBaseUrl}/api/query`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // Forward the formatted messages in the chat to the API
      body: JSON.stringify({
        query: mostRecentMsg,
        messages: formattedMessages,
      }),
      credentials: "include",
      // If the user hits the "cancel" button or escape keyboard key, cancel the request
      signal: abortSignal,
    });

    const data = await result.json();
    console.log(data);

    if ("plaintext" in data) {
      return {
        content: [
          {
            type: "text",
            text: data.plaintext,
          }
        ]
      }
    }

    return {
      content: [
        {
          type: "text",
          text: data.summary,
        },
        {
          type: "tool-call",
          toolCallId: "placeholder",
          toolName: "display_data",
          args: {}, // This needs to be here to satisfy TypeScript linter.
          result: JSON.stringify(data.table),
          argsText: "", // This needs to be here to satisfy TypeScript linter.
        },
      ],
    };
  },
};


// The model adapter below can stream data from the backend.
// It currently does not make much sense since the summary takes time to be formed on the backend anyways.
// Can revisit this in the future. Note: need to update backend API to use "yield" in order to provide streaming.

// const decoder = new TextDecoder('utf-8');

// const MyModelAdapterWithStreaming: ChatModelAdapter = {
//   async *run({ messages, abortSignal, config }) {
//     const apiBaseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;

//     const response = await fetch(`${apiBaseUrl}/api/query`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       // forward the messages in the chat to the API
//       body: JSON.stringify({
//         messages,
//       }),
//       // if the user hits the "cancel" button or escape keyboard key, cancel the request
//       signal: abortSignal,
//     });

//     const reader = response.body?.getReader();
//     let partialChunk = "";

//     while (true) {
//       const { done, value } = await reader.read();
//       if (done) break;
//       console.log(value);

//       partialChunk += decoder.decode(value, { stream: true });
    
//       // Process each complete chunk
//       let chunks = partialChunk.split("\n\n");
//       partialChunk = chunks.pop(); // Save the incomplete chunk for later

//       for (const chunk of chunks) {

//         const parsed = JSON.parse(chunk);
//         console.log(parsed);
//         console.log(parsed.summary);
//         yield {
//           content: [
//             {
//               type: "text",
//               text: parsed.summary,
//             },
//           ]
//         };
//         // try {
//         //   const parsed: StreamChunk = JSON.parse(chunk);
//         //   if (parsed.type === 'summary') {
//         //     displaySummary(parsed.data as string);
//         //   } else if (parsed.type === 'table_row') {
//         //     appendTableRow(parsed.data as Record<string, any>);
//         //   }
//         // } catch (error) {
//         //   console.error('Error parsing chunk:', error);
//         // }
//       }
//     }

 
//     // let text = "";
//     // for await (const part of stream) {
//     //   console.log(part);
//     //   text += part.choices[0]?.delta?.content || "";
 
//     //   yield {
//     //     content: [
//     //       {
//     //         type: "text",
//     //         text: data.summary,
//     //       },
//     //       {
//     //         type: "tool-call",
//     //         toolName: "display_data",
//     //         args: JSON.stringify(data.table),
//     //         // result: {}, // you can also put your json in here, it doesn't matter much
//     //       }
//     //     ],
//     //   };
//     // }
//   },
// };
 
export function MyRuntimeProvider({
  children,
}: Readonly<{
  children: ReactNode;
}>) {
  const runtime = useLocalRuntime(MyModelAdapter);
 
  return (
    <AssistantRuntimeProvider runtime={runtime}>
      {children}
    </AssistantRuntimeProvider>
  );
}
"use client"

import "./globals.css";
import "./layout.css"; // Import the layout-specific CSS


import { cn } from "@/lib/utils";
import { Montserrat } from "next/font/google";
import { MyRuntimeProvider } from "./MyRuntimeProvider";
import { ProfileButton } from "@/components/ProfileButton";
import { HelpButton } from "@/components/HelpButton";
import Link from "next/link";

const montserrat = Montserrat({ subsets: ["latin"] });

export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <MyRuntimeProvider>
      <html lang="en">
        <body className={cn(montserrat.className, "h-dvh")}>

          <div className="layout-container">
            {/* Sticky Header */}
            <header className="header">
              <Link href="/" className="header-title-link">
                <h1 className="header-title">CoverBoy</h1>
              </Link>
              <div className="header-right">
                <div className="profile-button">
                  <ProfileButton></ProfileButton>
                </div>
                <div className="help-button">
                  <HelpButton></HelpButton>
                </div>
              </div>
            </header>

            {/* Page Content and Chatbot */}
            <main className="content">{children}</main>
          </div>
        </body>
      </html>
    </MyRuntimeProvider>
  );
}
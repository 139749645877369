"use client";

import "./page.css"; // Import the layout-specific CSS

import { Thread } from "@assistant-ui/react";
import { makeMarkdownText } from "@assistant-ui/react-markdown";
import { DisplayDataTool } from "@/components/tools/DisplayDataTool";

const MarkdownText = makeMarkdownText();
 

export default function Home() {
  return (
    <div className="flex h-full flex-col">
      <Thread
        welcome={{
          suggestions: [
            {
              prompt: "What are Patrick Mahomes passing stats the last 5 playoff games? Add betting info.",
            },
            {
              prompt: "Saquon Barkley 2H rushing stats when the Eagles are leading by 7 or more points and Jalen Hurts is starting at QB",
            },
          ],
        }}
        assistantMessage={{ components: { Text: MarkdownText } }}
        tools={[DisplayDataTool]}
      />
    </div>
  );
}
"use client"

import { useState } from 'react';
import { useRouter } from 'next/navigation'

import "./HelpButton.css";

export const HelpButton = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const router = useRouter();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navigateToAbout = () => {
    router.push('/about');
  };

  return (
    <div className="help-button-container">
      <button className="help-button" onClick={toggleMenu}>?</button>

      {isMenuOpen && (
        <div className="help-menu">
          <button className="help-menu-item" onClick={navigateToAbout}>
            About and FAQ
          </button>
        </div>
      )}
    </div>
  );
};

"use client";

import { makeAssistantToolUI } from "@assistant-ui/react";
import { Table, TableColumnType } from "antd";
import { SortOrder } from "antd/es/table/interface";
import "./DisplayDataTool.css";

// I don't know why we need this. Placeholder for now.
type DisplayDataToolArgs = {
  placeholder: string;
};

// Define the structure of a table row
type TableRow = {
  [key: string]: string | number | null; // Adjust this type based on your actual data
};


export const DisplayDataTool = makeAssistantToolUI<
  DisplayDataToolArgs,
  string
>({
  toolName: "display_data",
  render: function DisplayDataUi({ result }) {
    if (result === null || result === undefined) {
      return;
    }
    const table = JSON.parse(result as string);

    if (table.length === 0) {
      return;
    }
    
    const keys = Object.keys(table[0]); // Extract column names from the first row
    const columns: TableColumnType<TableRow>[] = keys.map((key) => ({
      title: key.toUpperCase(),
      dataIndex: key,
      key: key,
      sorter: (a, b) => {
        // Handle numbers
        if (typeof a[key] === "number" && typeof b[key] === "number") {
          return a[key] - b[key];
        }
    
        // Handle strings (case-insensitive comparison)
        if (typeof a[key] === "string" && typeof b[key] === "string") {
          return a[key].localeCompare(b[key]);
        }
    
        // Default case: no sorting
        return 0;
      },
      sortDirections: ["ascend", "descend"] as SortOrder[], // Optional: Enable both ascending and descending sorts
      fixed: key === 'Game Info' ? 'left' : undefined, // Fix the 'Week' column to the left
      className: key === 'Game Info' ? 'fixed-column-left' : undefined,
      width: key === 'Game Info' ? 125 : undefined,
      // Add filters dynamically based on unique values
      // filters: generateFiltersForColumn(key),
      onFilter: (value, record) => record[key] === value,
    })); // eslint-disable-line @typescript-eslint/no-explicit-any

    const rows = table.map((row, index) => ({ id: index, ...row }));


    return (
      <div className="data-grid">
        <Table
          className="ant-table-wrapper"
          columns={columns}
          dataSource={rows}
          rowClassName={(record, index) => (index % 2 === 0 ? 'even-row' : 'odd-row')}
          pagination={{ position: ['bottomCenter'] }}
        />
      </div>
    );
  },
});

